body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

header {
  background-color: #ffffff;
  color: #222222;
  height: 100px;
  width: 100%;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1000;
}
.header-content{
  padding :0px 7%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-links label{
  
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0px 30px;
  cursor: pointer;
}
.ggc{
  background: rgb(226,201,247);
background: linear-gradient(90deg, rgba(226,201,247,1) 0%, rgba(206,225,250,1) 13%, rgba(207,214,255,1) 35%, rgba(211,216,243,1) 76%, rgba(237,210,236,1) 100%);
}
.footer-info-p{
 color: #CFD6FF;
 font-size: 1.3rem;
 margin-bottom: 30px;
 
}
.logo{
  background-image: url("../public/rtplogo3.png");
  padding: 40px;
  background-position: center;
  background-size: 90%;
  background-repeat: no-repeat;
  margin-right: 10px;
  
}
.logo-title{
  font-size: 1.5rem;
  font-weight: 900;
  
}
.home-section{
  text-align: center;
  padding: 50px 7%;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.text-container {
  text-align: left;
  margin-right: 40px;
}

.text-container h1 {
  font-size: 2.5rem;
  letter-spacing: 2px;
}

.text-container p {
  font-size: 26px;
  letter-spacing: 1px;
  margin-bottom: 50px;
}

.text-container button {
  background-color: #001392;
  padding: 20px 40px;
  font-size: 1.3rem;
  color: #FFFFFF;
  font-weight: 600;
  border-radius: 50px;
  border: none;
  cursor: pointer;
}

.text-container button:hover {
  background-color: #0033a0; /* Optional: Slightly lighter shade on hover */
}
.about-section {
  padding: 50px 7%;
  text-align: center;
}

.about-section label {
  font-size: 3rem;
  font-weight: 500;
}

.about-section p {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 30px;
}

.about-cards-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.about-card {
  background-color: rgba(108, 116, 180, 0.7);
  margin-right: 20px;
  border-radius: 10px;
  width: 30%;
  text-align: left;
}

.about-card:last-child {
  margin-right: 0; /* Removes margin on the last card */
}

.about-card label {
  font-size: 1.5rem;
  font-weight: 500;
  color: #FFFFFF;
  display: block;
  padding-top: 20px;
  padding-left: 20px;
}

.about-card p {
  font-size: 1.4rem;
  color: #FFFFFF;
  margin-top: 10px;
  padding: 0px 20px;
  font-weight: normal;
}
/* Services Section Styles */
.services-section {
  padding: 50px 7%;
  background-color: #FFFFFF;
  text-align: center;
}

.services-section label {
  font-size: 3rem;
  font-weight: 500;
}

.services-section p {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 60px;
  font-weight: 500;
}

.services-container {
  display: flex;
  justify-content: center;
}

.services-image {
  background-image: url('../public/ser.png'); /* Update path as needed */
  width: 40%;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 30px;
}

.services-details {
  align-self: flex-start;
  text-align: left;
  width: 40%;
}

.services-list {
  list-style: none;
  font-size: 1.3rem;
  margin: 0;
}

.services-list li {
  margin-bottom: 30px; /* Default spacing between items */
}

.services-list li strong {
  font-weight: 500;
  margin-bottom: 5px;
  display: block; /* Ensures the title is on a new line */
}

.services-list li:last-child {
  margin-bottom: 0; /* Removes margin for the last item */
}
/* Contact Section Styles */
.contact-section {
  padding: 50px 7%;
  background-color: #000A4C;
}

.contact-container {
  display: flex;
  justify-content: space-around;
}

.contact-info {
  width: 50%;
  
}

.contact-info label {
  color: #CFD6FF;
  font-size: 2rem;
  font-weight: 500;
}

.contact-info p {
  margin-top: 30px;
  color: #CFD6FF;
  font-size: 1rem;
  margin-bottom: 15px;
 
}

.contact-form-container {
 background-color: #444c83;
 padding: 30px;
 border-radius: 10px;
 text-align: center;
}
.contact-form-container label {
  color: #CFD6FF;
  font-size: 1rem;
}
.contact-form-container>label {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 15px;
  display: block;
}

.contact-form-container p {
  color: #CFD6FF;
  font-size: 1rem;
  margin: 0px;
  margin-bottom: 2px;
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.contact-form input,
.contact-form textarea {
  margin-top: 10px;
  margin-bottom: 20px;
  border: 0;
  border-radius: 10px;
  padding: 20px;
  background-color: #000a4c;
  color: #FFFFFF;
}
.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #8188b4; 
  font-size: 1rem;
  opacity: 1;
}
.contact-form label{
  display: none;
}
.contact-form textarea {
  resize: none;
}

.contact-form button {
  padding: 15px;
  border: none;
  background-color: #CFD6FF;
  font-size: 1rem;
  color: #000A4C;
  font-weight: 600;
  margin-top: 20px;
  border-radius: 40px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #E0E6FF; /* Optional: Slight hover effect */
}

.logo-sub-title{
  letter-spacing: 0.14rem;
}
.contact-svn{
  display: flex;
  align-items: center;
}
.message-info{
  background-color: #00b94a;
  padding: 20px;
  color: #FFF;
  border-radius: 5px;
  font-size: 1rem;
  position: fixed;
  z-index: 100;
  bottom: 20px;
  right: 10px;
}
@media (min-width: 967px) and (max-width: 1100px) {
  .header-content{
    padding :0px 20px;
  }
  .home-section{
    padding: 50px 20px;
  }
  .about-section {
    padding: 50px 20px;
  }
  .services-section {
    padding: 50px 10px;
  }
  .contact-section {
    padding: 50px 20px;
  }
}
@media (max-width: 967px) {
  .services-list{
    padding: 0;
  }
  .contact-svn{
    align-items: flex-start;
  }
  .logo-sub-title{
    letter-spacing: 0.12rem;
  }
  .header-content{
    padding: 0px;
  }
  .contact-form-container{
    margin-top: 20px;
  }
  .contact-section{
    padding: 30px;
  }
  .contact-container{
    flex-direction: column;
  }
  .contact-info{
    width: 100%;
  }
 
  .services-section {
    padding: 30px;
    
  }
  .services-image{
    display: none;
  }
  .services-details{
    width: 100%;
    text-align: center;
  }
  .about-card {
    width: 100%;
    margin-right: 0px;
    text-align: center;
    margin-bottom: 20px;
  }
  .about-cards-container{
    flex-direction: column;
  }
  .about-section {
    padding: 30px;
    text-align: center;
  }
  .text-container {
    text-align: center;
    margin-right: 0px;
  }
  .home-section{
    padding: 30px;
  }
  header {
    
    padding: 10px;
  }
  .logo{
    background-size: 90%;
  }
  .logo-title {
    font-size: 1.5rem;
  }
  .header-links {
    flex-direction: column;
    gap: 10px;
    width: 100%;
    display: none;
  }

  .header-links label {
    text-align: left;
    font-size: 14px;
  }

  .home-logo{
    display: none;
  }
  
}